import HeroWithMedia from '@rsa-digital/evo-shared-components/components/Hero/HeroWithMedia';
import { Heading } from '@rsa-digital/evo-shared-components/components/Hero/HeroWithMedia/styles';
import HeroWithResponsiveMedia from '@rsa-digital/evo-shared-components/components/Hero/HeroWithResponsiveMedia';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import styled, { css } from 'styled-components';

const commonHeroWithMediaStyle = css`
  ${Heading} {
    font-size: 52px;
    line-height: 60px;
    ${mediaQuery.tabletPortrait`
      ${fonts.headingXLarge}
      font-size: 72px;
      line-height: 80px;
    `}
  }
`;

export const StyledHeroWithMedia = styled(HeroWithMedia)`
  ${commonHeroWithMediaStyle}
`;

export const StyledHeroWithResponsiveMedia = styled(HeroWithResponsiveMedia)`
  ${commonHeroWithMediaStyle}
`;
