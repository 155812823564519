import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';
import React from 'react';
import VideoCookieNotice from 'components/VideoCookieNotice';
import {
  processImageAsset,
  processOptionalCta,
  processSimpleVideo,
} from 'helpers/csTypeProcessors';
import { CsAsset, CsCta, CsVideo } from 'types/contentStack';
import { StyledHeroWithMedia, StyledHeroWithResponsiveMedia } from './styles';

export type HeroWithMediaData = {
  heading: string;
  subheading: string | null;
  body: string | null;
  image: CsAsset | null;
  caption: string | null;
  video: [CsVideo] | null;
  primary_cta: [CsCta] | null;
  secondary_cta: [CsCta] | null;
  bottom_space_height: string | null;
  add_background_colour_to_bottom_space: boolean | null;
};

type HeroWithMediaBlockProps = {
  hero_with_media: HeroWithMediaData;
  showResponsiveVersion: boolean;
};

export const query = graphql`
  fragment HeroWithMedia on cs__hero_with_media {
    heading
    subheading
    body
    caption
    image {
      ...Asset
    }
    video {
      ...Video
    }
    primary_cta {
      ...Cta
    }
    secondary_cta {
      ...Cta
    }
    bottom_space_height
    add_background_colour_to_bottom_space
  }
`;

// The unit for bottom_space_height is RSA's layout.baseSpacingPx
// See DigitalSharedComponents\theme\RSA\layout.ts
// layout.baseSpacingPx: 8 (pixels)
// Hence, for instance, a value of '3' is equivalent to 24px

const HeroWithMediaBlock: React.FC<HeroWithMediaBlockProps> = ({
  hero_with_media,
  showResponsiveVersion,
}) => {
  const commonHeroProps = {
    heading: hero_with_media.heading,
    subhead: hero_with_media.subheading ?? undefined,
    body: hero_with_media.body ?? undefined,
    image: processImageAsset(hero_with_media.image),
    vide: !isEmpty(hero_with_media.video)
      ? processSimpleVideo(hero_with_media.video)
      : undefined,
    primaryCta: processOptionalCta(
      hero_with_media.primary_cta,
      'HeroWithMediaPrimaryCta'
    ),
    secondaryCta: processOptionalCta(
      hero_with_media.secondary_cta,
      'HeroWithMediaSecondaryCta'
    ),
    CookieNoticeComponent: VideoCookieNotice,
  };

  // We parse the height as float to allow for smaller spacing steps
  // For instance, spacing(1.5), equivalent to 12px
  const bottomSpaceHeight = parseFloat(hero_with_media?.bottom_space_height ?? '0');
  const addBackgroundColourToBottomSpace = !!hero_with_media?.add_background_colour_to_bottom_space;

  return (
    <>
      {showResponsiveVersion ? (
        <StyledHeroWithResponsiveMedia
          data-cy="HeroBannerWithResponsiveMedia"
          {...commonHeroProps}
          bottomSpaceHeight={bottomSpaceHeight}
          addBackgroundColourToBottomSpace={addBackgroundColourToBottomSpace}
        />
      ) : (
        <StyledHeroWithMedia
          data-cy="HeroBannerWithMedia"
          {...commonHeroProps}
          hideTriangle
          caption={hero_with_media.caption ?? undefined}
        />
      )}
    </>
  );
};
export default React.memo(HeroWithMediaBlock);
